import * as yup from "yup";
export const productSchema = yup.object().shape({
  name_ar: yup.string().required("هذا الحقل مطلوب"),
  price_before: yup
    .number()
    .typeError("برجاء ادخال ارفام فقط")
    .required("هذا الحقل مطلوب"),
  price_after: yup
    .number()
    .typeError("برجاء ادخال ارفام فقط")
    .required("هذا الحقل مطلوب"),
  quantity: yup.number().typeError("برجاء ادخال ارفام فقط"),
  // .required("هذا الحقل مطلوب"),
  unit: yup.string(),
  // .required("هذا الحقل مطلوب"),
});
