import React, { useContext } from "react";
import ButtonBack from "../../components/Buttons/ButtonBack/ButtonBack";
import Model from "../../components/Model/Model";
import ProfileBox from "../../components/Nav/ProfileBox/ProfileBox";
import MerchantsImg from "../../images/merchants.svg";
import profileImg from "../../images/profile.svg";
import addMerchantImg from "../../images/addMerchant.svg";

import "./SettingsPage.scss";
import { Context } from "../../context/Context";
const SettingsPage = () => {
  const { adminProfileData } = useContext(Context);
  const { is_admin } = adminProfileData;
  return (
    <>
      <ButtonBack />

      <Model title="الاعدادات" caption="" />
      <div className="settings-boxes-wrapper">
        <ProfileBox
          className={"Settings-box1"}
          icon=""
          title="الملف الشخصي"
          to="profile"
          img={profileImg}
        />
        {is_admin ? (
          <>
            <ProfileBox
              className={"Settings-box1"}
              icon=""
              title="قائمة التجار"
              to="/merchants"
              img={MerchantsImg}
            />
            <ProfileBox
              className={"Settings-box2"}
              icon=""
              title="اضافة تاجر"
              to="/add-merchant"
              img={addMerchantImg}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SettingsPage;
