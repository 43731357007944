import React, { useContext, useEffect } from "react";
import { Context } from "../../context/Context";
import CardProduct from "../Card/CardProduct";
import CategoryHead from "../CategoryHead/CategoryHead";
import Container from "../Container/Container";
import LoadingTable from "../Table/LoadingTable/LoadingTable";
import Table from "../Table/Table";
import "./Category.scss";

const Category = ({
  tableData,
  arCategory,
  enCategory,
  categoryImg,
  isLodingData,
  paginationPages,
  onClick,
  onChange,
  defaultValue,
  searchText,
  to,
}) => {
  return (
    <>
      <Container>
        <CategoryHead
          arCategory={arCategory}
          enCategory={enCategory}
          categoryImg={categoryImg}
          isLodingData={isLodingData}
          onClick={onClick}
          onChange={onChange}
          defaultValue={defaultValue}
          addIcon="fa-solid fa-plus"
          btnText="اضافة"
          to={to}
        />
        {isLodingData ? (
          <LoadingTable />
        ) : (
          <>
            <Table
              tableData={tableData}
              paginationPages={paginationPages}
              searchText={searchText}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default Category;
