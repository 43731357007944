import React from "react";
import "./LoadingTable.scss";

const LoadingTable = () => {
  return (
    <div className="loading-table">
      <i className="fas fa-spinner fa-spin loading-icon"></i>
    </div>
  );
};

export default LoadingTable;
