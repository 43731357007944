import axios from "axios";
import { toast } from "react-toastify";
const logoutApi = async (isLoading, setToken, navigateTo) => {
  isLoading(true);
  try {
    await axios.get(`/logout`);
    isLoading(false);
    navigateTo("/login");
    localStorage.removeItem("userToken");
    setToken("");
    toast.success("تم تسجيل الخروج بنجاح");
    return;
  } catch (error) {
    isLoading(false);
    // toast.error(
    //   error?.message || error?.response?.date?.message || "Something went wrong"
    // );
    localStorage.removeItem("userToken");
    navigateTo("login");
    return;
  }
};
export default logoutApi;
