import React from "react";
import { Link } from "react-router-dom";
import "./ButtonEdit.scss";

const ButtonEdit = ({ onClick, to }) => {
  return (
    <Link to={to}>
      <button className="btn-edit" onClick={onClick}>
        <span className="btn-text">تعديل</span>
        <i className="fa-regular fa-pen-to-square btn-icon"></i>
      </button>
    </Link>
  );
};

export default ButtonEdit;
