import React from "react";
import "./CategoryTitle.scss";

const CategoryTitle = ({
  arCategory,
  enCategory,
  categoryImg,
  titleClassName,
}) => {
  return (
    <>
      <div className="category-title-wrapper">
        <div className="category-img-wrapper">
          <img className="category-img" src={categoryImg} alt="" />
        </div>
        <div className={`category-title-text ${titleClassName}`}>
          <h3 className="category-ar">{arCategory}</h3>
          <p className="category-en">{enCategory}</p>
        </div>
      </div>
    </>
  );
};

export default CategoryTitle;
