import React from "react";
import "./ButtonMain.scss";

const ButtonMain = ({
  className,
  type,
  btnText,
  onClick,
  disabled,
  isLoading,
}) => {
  return (
    <button
      type={type}
      className={`custom-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {/* {btnText} */}
      {isLoading ? <i className="fas fa-spinner fa-spin"></i> : `${btnText}`}
    </button>
  );
};

export default ButtonMain;
