import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../context/Context";
import "./CategoryNav.scss";

const CategoryNav = ({
  className,
  navSideImg,
  navSideTextAr,
  navSideTextEn,
  to,
  imgClass,
}) => {
  const { setIsClicked } = useContext(Context);
  return (
    <Link className="link-style" to={to} onClick={() => setIsClicked(false)}>
      <div className={`category-side ${className}`}>
        <div className="category-img-wrapper">
          <img src={navSideImg} alt="" className={`${imgClass} `} />
        </div>
        <div className="category-side-head">
          <p className="category-side-text-ar">{navSideTextAr}</p>
          <p className="category-side-text-en">{navSideTextEn}</p>
        </div>
      </div>
    </Link>
  );
};

export default CategoryNav;
