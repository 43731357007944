import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import getProductsApi from "../../apis/getProductsApi";
import { Context } from "../../context/Context";
import "./Pagination.scss";

export const Pagination = ({ paginationPages, className, searchText }) => {
  const {} = useContext(Context);
  const navigate = useNavigate();
  const {
    currentCategory,
    setVegetablesData,
    setIsVegetablesDataLoading,
    setVegetablesPaginationLength,
    setFruitsData,
    setIsFruitsDataLoading,
    setFruitsPaginationLength,
    setPapersData,
    setIsPapersDataLoading,
    setPapersPaginationLength,
  } = useContext(Context);
  const goToPage = async (pageNum = 1, text = "") => {
    if (!text) {
      navigate({
        search: `page=${pageNum}`,
      });
    } else {
      navigate({
        search: `page=${pageNum}&name=${text}`,
      });
    }
  };
  const callPaginationProducts = (page = 1, searchText = "") => {
    goToPage(page, searchText);

    if (currentCategory == "vegetables") {
      getProductsApi(
        setVegetablesData,
        setIsVegetablesDataLoading,
        1,
        page,
        setVegetablesPaginationLength,
        searchText
      );
    } else if (currentCategory == "fruits") {
      getProductsApi(
        setFruitsData,
        setIsFruitsDataLoading,
        2,
        page,
        setFruitsPaginationLength,
        searchText
      );
    } else if (currentCategory == "papers") {
      getProductsApi(
        setPapersData,
        setIsPapersDataLoading,
        3,
        page,
        setPapersPaginationLength,
        searchText
      );
    }
  };

  return (
    <div className="pagination-wrapper">
      {paginationPages?.map((page, i) => (
        <button
          onClick={() => callPaginationProducts(i + 1, searchText)}
          key={i}
          className={`pagination-link ${className}`}
        >
          {page + 1}
        </button>
      ))}
    </div>
  );
};
export default Pagination;
