import React from "react";

import vegetableBigImg from "../../../images/vegetableBigImg.svg";
import EditProductForm from "../../../components/Form/EditProductForm";

const EditVegetable = () => {
  return (
    <EditProductForm
      categoryImg={vegetableBigImg}
      arCategory="الخضار"
      enCategory="Vegetables"
      category_id={1}
    />
  );
};

export default EditVegetable;
