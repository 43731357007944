import React from "react";
import CategoryTitle from "../CategoryTitle/CategoryTitle";
import Container from "../Container/Container";
import "./FormContainer.scss";
const FormContainer = ({ children, arCategory, categoryImg, enCategory }) => {
  return (
    <Container className="form-container">
      <CategoryTitle
        arCategory={arCategory}
        enCategory={enCategory}
        categoryImg={categoryImg}
      />
      {children}
    </Container>
  );
};

export default FormContainer;
