import axios from "axios";
// import { toast } from "react-toastify";
const getMerchantsApi = async (
  callback,
  isLoading,
  page = 1,
  paginationLength,
  isAdmin,
  name = ""
) => {
  if (isAdmin) {
    isLoading(true);

    try {
      let response = await axios.get(`/all-users?name=${name}&page=${page}`);
      isLoading(false);
      const { data } = response?.data;
      const { is_pagination, total_pages } = data?.pagination;
      if (total_pages > 1) {
        paginationLength([...Array(total_pages).keys()]);
      } else {
      }
      return callback(data || {});
    } catch (error) {
      isLoading(false);
      // toast.error(
      //   error?.message ||
      //     error?.response?.date?.message ||
      //     "Something went wrong"
      // );
    }
  }
};
export default getMerchantsApi;
