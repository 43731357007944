import React from "react";
import { Link } from "react-router-dom";
import CategoryTitle from "../../CategoryTitle/CategoryTitle";
import "./CategoryBox.scss";

const CategoryBox = ({
  categoryImg,
  arCategory,
  enCategory,
  to,
  className,
  titleClassName,
}) => {
  return (
    <Link className={`link-style category-box home-box ${className} `} to={to}>
      <CategoryTitle
        arCategory={arCategory}
        enCategory={enCategory}
        categoryImg={categoryImg}
        titleClassName={titleClassName}
      />
    </Link>
  );
};

export default CategoryBox;
