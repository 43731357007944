import React, { useContext } from "react";
import ButtonBack from "../../components/Buttons/ButtonBack/ButtonBack";
import Model from "../../components/Model/Model";
import "./Profile.scss";
import iconUserGreen from "../../icons/iconUserGreen.svg";
import ButtonMain from "../../components/Buttons/ButtonMain/ButtonMain";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
const Profile = () => {
  const { adminProfileData } = useContext(Context);
  return (
    <>
      <div className="profile-wrapper">
        <ButtonBack />
        <Model
          title="الملف الشخصي"
          caption="عرض معلومات المستخدم وتعديل كلمة المرور"
        />
        <div className="profile-info">
          <p className="profile-user text-align">
            {/* <img src={iconUserGreen} alt="" className="profile-icon" /> */}
            {adminProfileData?.name}
          </p>
          <p className="profile-user text-align">{adminProfileData?.phone}</p>
        </div>

        <Link className="link-style" to="edit-profile-info">
          <ButtonMain btnText="تعديل الملف الشخصي " />
        </Link>
        <Link className="link-style" to="edit-profile-password">
          <ButtonMain btnText="تعديل كلمة المرور" />
        </Link>
      </div>
    </>
  );
};

export default Profile;
