import axios from "axios";
import { toast } from "react-toastify";
const deleteProductApi = async (id, loading, setIsConfirming, toggle) => {
  loading(true);

  try {
    let response = await axios.post(`/delete-product`, {
      id: id,
    });
    loading(false);
    if (response?.status === 200 && response?.data?.success) {
      toggle();
      toast.success("تم حذف المنتج بنجاح");
      setIsConfirming(false);
      return;
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
    setIsConfirming(false);
    return;
  }
};
export default deleteProductApi;
