import React, { useContext, useState } from "react";
import "./Logout.scss";
import iconLogout from "../../icons/iconLogout.svg";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import logoutApi from "../../apis/logoutApi";
import { Context } from "../../context/Context";

const Logout = () => {
  const { navigateTo, setToken } = useContext(Context);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoggingoutLoading, setIsLoggingoutLoading] = useState(false);

  return (
    <>
      <button
        className="floating-logout-btn"
        type="button"
        onClick={() => setIsConfirming(true)}
      >
        <img className="float-icon" src={iconLogout} alt="" />
      </button>
      {isConfirming && (
        <ConfirmBox
          exitBtn={() => setIsConfirming(false)}
          title={"تسجيل الخروج"}
          caption="هل تريد تسجيل الخروج"
          confirmText="نعم, تسجيل خروج"
          confirmYes={() =>
            logoutApi(setIsLoggingoutLoading, setToken, navigateTo)
          }
          className="confirm-box-layer"
          isLoading={isLoggingoutLoading}
        />
      )}
    </>
  );
};

export default Logout;
