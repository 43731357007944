import { useFormikContext } from "formik";
import React from "react";
import "./Checkbox.scss";

const Checkbox = ({ inputKey }) => {
  const { handleChange, values } = useFormikContext();
  return (
    <div className="select-input-wrapper">
      <select
        onChange={handleChange}
        name={inputKey}
        id={inputKey}
        defaultValue={values[inputKey]}
        className="select-container"
      >
        <option value={0}>لا</option>
        <option value={1}>نعم</option>
      </select>
      <label htmlFor={inputKey}>اظهار المنتج في المقدمة</label>
    </div>
  );
};

export default Checkbox;
