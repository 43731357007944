import React from "react";
import AddProductForm from "../../../components/Form/AddProductForm";
import paperBigImg from "../../../images/paperBigImg.svg";
import "./AddPaper.scss";
const AddPaper = () => {
  return (
    <AddProductForm
      categoryImg={paperBigImg}
      arCategory="ورقيات"
      enCategory="Papers"
      category_id={3}
    />
  );
};

export default AddPaper;
