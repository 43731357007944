import axios from "axios";
import { toast } from "react-toastify";
const loginApi = async (values, loading, setToken, navigate) => {
  localStorage.removeItem("userToken");
  loading(true);
  try {
    let response = await axios.post(`/login`, values);
    loading(false);
    if (response?.status === 200 && response?.data?.success) {
      localStorage.setItem("userToken", response?.data?.data?.token);
      await setToken(localStorage.getItem("userToken"));
      navigate("./");
      toast.success("تم تسجيل الدخول بنجاح");
      return;
    } else if (
      response?.status === 200 &&
      response?.data?.response_code === 400
    ) {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default loginApi;
