import React, { useEffect } from "react";
import "./ConfirmBox.scss";

const ConfirmBox = ({
  className,
  title,
  caption,
  confirmText,
  confirmYes,
  exitBtn,
  isLoading,
  isConfirming,
}) => {
  useEffect(() => {
    isConfirming && (document.body.style.overflow = "hidden");
  });

  return (
    <>
      <div className={`confirm-wrapper ${className}`}>
        <div className="confirm-box">
          <div className="confirm-head">
            <h4 className="confirm-title">{title}</h4>
            <h4 className="confirm-caption">{caption}</h4>
          </div>
          <div className="confirm-btns">
            <button
              className="confirm-btn confirm-btn-yes"
              onClick={confirmYes}
            >
              {/* {confirmText} */}
              {isLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                `${confirmText}`
              )}
            </button>
            <button className="confirm-btn confirm-btn-no" onClick={exitBtn}>
              لا
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmBox;
