import React, { useContext, useEffect, useState } from "react";
import CategoryNav from "./CategoryNav/CategoryNav";
import "./NavSide.scss";
import vegetableBigImg from "../../images/vegetableBigImg.svg";
import fruitSmallImg from "../../images/fruitSmallImg.svg";
import paperSmallImg from "../../images/paperSmallImg.svg";
import settingsImg from "../../images/settings.svg";

import { useLocation } from "react-router-dom";
import { Context } from "../../context/Context";

const NavSide = () => {
  const { isClicked, setIsClicked } = useContext(Context);
  const location = useLocation();

  const [currentCategory, setCurrentCategory] = useState(
    location.pathname.slice(1)
  );
  useEffect(() => {
    setCurrentCategory(location.pathname.slice(1));
  }, [location.pathname]);
  const handleClick = () => {
    // for navBar mobile handleClick OR
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };
  return (
    <>
      <div className="mobile-nav">
        <button
          onClick={handleClick}
          className={
            isClicked
              ? "float-icon floating-logout-btn mobile-bar inactive-icon"
              : "float-icon floating-logout-btn mobile-bar "
          }
          type="button"
        >
          <i className={isClicked ? "fas fa-times" : "fas fa-bars "}></i>
        </button>
      </div>
      <div
        className={
          isClicked ? "nav-side-wrapper active-nav" : "nav-side-wrapper"
        }
      >
        <div className={isClicked ? "nav-side active-nav" : "nav-side"}>
          <div className="nav-side-content">
            <CategoryNav
              navSideImg={vegetableBigImg}
              imgClass="Category-side-img"
              navSideTextAr="الخضار"
              navSideTextEn={"Vegetables"}
              to="/vegetables"
              className={
                currentCategory === "vegetables"
                  ? "active-side"
                  : currentCategory === "vegetables/add-product"
                  ? "active-side"
                  : currentCategory === "vegetables/edit-product" &&
                    "active-side"
              }
            />
            <CategoryNav
              navSideImg={fruitSmallImg}
              navSideTextAr="الفاكهة"
              navSideTextEn={"Fruits"}
              to="/fruits"
              className={
                currentCategory === "fruits"
                  ? "active-side"
                  : currentCategory === "fruits/add-product"
                  ? "active-side"
                  : currentCategory === "fruits/edit-product" && "active-side"
              }
            />
            <CategoryNav
              navSideImg={paperSmallImg}
              navSideTextAr="ورقيات"
              navSideTextEn={"Papers"}
              to="/papers"
              className={
                currentCategory === "papers"
                  ? "active-side"
                  : currentCategory === "papers/add-product"
                  ? "active-side"
                  : currentCategory === "papers/edit-product" && "active-side"
              }
            />
            <CategoryNav
              navSideImg={settingsImg}
              navSideTextAr="اعدادات"
              navSideTextEn={"Settings"}
              to="/settings"
              className="nav-settings"
            />
            {/* <ProfileNav /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavSide;
