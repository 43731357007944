import axios from "axios";
// import { toast } from "react-toastify";
const getAdmainProfileApi = async (callback, isLoading) => {
  isLoading(true);
  try {
    let response = await axios.get(`/profile`);
    isLoading(false);
    return callback(response?.data?.data);
  } catch (error) {
    isLoading(false);
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.success === false
    ) {
      localStorage.removeItem("userToken");
    }
  }
};
export default getAdmainProfileApi;
