import React, { useContext, useState } from "react";
import deleteMerchantApi from "../../../apis/deleteMerchantApi";

import { Context } from "../../../context/Context";
import ConfirmBox from "../../ConfirmBox/ConfirmBox";
import "./ButtonDelete.scss";

const ButtonDeleteMerchant = (props) => {
  const { getToggle } = useContext(Context);
  const [isDeletingMerchant, setIsDeletingMerchant] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [currentItemId, setCurrentItemId] = useState("");
  const getCurrentItemId = (id) => {
    setIsConfirming(true);
    setCurrentItemId(id);
  };

  return (
    <>
      <button
        className="btn-delete"
        onClick={() => getCurrentItemId(props.currentItem)}
      >
        <i className="fa-solid fa-trash"></i> مسح
      </button>
      {isConfirming && (
        <ConfirmBox
          exitBtn={() => setIsConfirming(false)}
          title={"مسح التاجر"}
          caption="هل تريد مسح التاجر"
          confirmText="نعم, امسح التاجر"
          confirmYes={() =>
            deleteMerchantApi(
              currentItemId,
              setIsDeletingMerchant,
              setIsConfirming,
              getToggle
            )
          }
          isLoading={isDeletingMerchant}
        />
      )}
    </>
  );
};

export default ButtonDeleteMerchant;
