import React, { useState } from "react";
import "./Input.scss";
import iconPassword from "../../icons/iconPassword.svg";
import { ErrorMessage, useFormikContext } from "formik";
const InputPassword = ({ className = "", placeholder, inputKey }) => {
  const { values, handleChange, handleBlur } = useFormikContext();
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <div className={`custom-input ${className}`}>
      <img className="input-icon" src={iconPassword} alt="" />
      <input
        type={passwordShown ? "text" : "password"}
        className="input"
        placeholder={placeholder}
        name={inputKey}
        id={inputKey}
        value={values[inputKey]}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <p className="error">
        <ErrorMessage name={inputKey} />
      </p>
      {passwordShown ? (
        <i
          className="fa-regular fa-eye password-icon"
          onClick={() => setPasswordShown(false)}
        ></i>
      ) : (
        <i
          className="fa-regular fa-eye-slash password-icon"
          onClick={() => setPasswordShown(true)}
        ></i>
      )}
    </div>
  );
};

export default InputPassword;
