import React from "react";
import EditMerchantForm from "../../../components/Form/EditMerchantForm";

function EditMerchant() {
  return (
    <>
      <EditMerchantForm />
    </>
  );
}

export default EditMerchant;
