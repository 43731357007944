import { Formik } from "formik";
import React, { useContext, useState } from "react";
import updateProfile from "../../../apis/updateProfile";
import ButtonBack from "../../../components/Buttons/ButtonBack/ButtonBack";
import Form from "../../../components/Form/Form";
import Input from "../../../components/Input/Input";
import Model from "../../../components/Model/Model";
import { Context } from "../../../context/Context";
import { updateProfileSchema } from "../../../schemas/updateProfileSchema";
import "../EditProfile/EditProfile.scss";
const EditProfileInfo = () => {
  const [isLodingEditProfile, setIsLodingEditProfile] = useState(false);

  const { navigateTo, adminProfileData, setAdminProfileData } =
    useContext(Context);
  const initialValues = {
    name: adminProfileData?.name,
    phone: adminProfileData?.phone,
  };
  return (
    <>
      <div className="edit-profile-wrapper">
        <ButtonBack />
        <Model
          title="الملف الشخصي"
          caption="يرجي تعديل اسم المستخدم باللغة الإنجليزية ورقم جوال"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={updateProfileSchema}
          onSubmit={(values) =>
            updateProfile(
              values,
              setIsLodingEditProfile,
              navigateTo,
              setAdminProfileData
            )
          }
        >
          <Form isLoading={isLodingEditProfile} btnText="تعديل">
            <Input placeholder="اسم المستخدم" inputKey="name" />
            <Input placeholder="رقم جوال الواتس أب" inputKey="phone" />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default EditProfileInfo;
