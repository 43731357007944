import React, { useContext, useState } from "react";
import Form from "../../components/Form/Form";
import Input from "../../components/Input/Input";
import Model from "../../components/Model/Model";
import iconUser from "../../icons/iconUser.svg";
import InputPassword from "../../components/Input/InputPassword";
import { Formik } from "formik";
import loginApi from "../../apis/loginApi";
import { Context } from "../../context/Context";
import { loginSchema } from "../../schemas/loginSchema";

const LoginModel = () => {
  const { navigateTo, navigate, setToken } = useContext(Context);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);

  const initialValues = {
    name: "",
    password: "",
  };
  return (
    <>
      <Model
        title="تسجيل الدخول"
        caption="يرجي كتابة اسم المستخدم و الرقم السري للدخول الي لوحة التحكم"
      />

      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={(values) =>
          loginApi(values, setIsLoadingLogin, setToken, navigate)
        }
      >
        <Form isLoading={isLoadingLogin} btnText="دخول">
          <Input
            inputIcon={iconUser}
            placeholder="اسم المستخدم"
            inputKey="name"
          />
          <InputPassword placeholder="كلمة المرور" inputKey="password" />
        </Form>
      </Formik>
    </>
  );
};

export default LoginModel;
