import React, { useEffect } from "react";
import "./NotFound.css";
const Notfound = () => {
  useEffect(() => {
    document.title = " مباشر - هذه الصفحة غير موجودة";
  });

  return (
    <div id="notFound">
      <h2>الصفحة غير موجودة</h2>
      <p> لم نتكمن من العثور على ما كنت تبحث عنه </p>
    </div>
  );
};

export default Notfound;
