import axios from "axios";
import { toast } from "react-toastify";
const getProductsApi = async (
  callback,
  isLoading,
  category,
  page = 1,
  paginationLength,
  name = ""
) => {
  isLoading(true);

  try {
    let response = await axios.get(
      `/user-products?category_id=${category}&name=${name}&page=${page}`
    );
    isLoading(false);
    const { data } = response?.data;
    const { is_pagination, total_pages } = data?.pagination;
    if (total_pages > 1) {
      paginationLength([...Array(total_pages).keys()]);
    } else {
    }
    return callback(data || {});
  } catch (error) {
    isLoading(false);
    console.log(error?.response?.date?.message);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default getProductsApi;
