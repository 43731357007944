import React from "react";
import ButtonDelete from "../Buttons/ButtonDelete/ButtonDelete";
import ButtonEdit from "../Buttons/ButtonEdit/ButtonEdit";
import "./Card.scss";

const CardProduct = ({
  className,
  productName,
  productQty,
  productUnit,
  priceBefore,
  priceAfter,
  currentItem,
  onClick,
}) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-product">
        <h3 className="card-title">المنتج</h3>
        <p className="card-captions product-name">
          {productName ? productName : ""}
        </p>
      </div>
      <div className="card-qty">
        <h3 className="card-title">الكمية</h3>
        <p className="card-captions">
          {productQty ? productQty : ""}{" "}
          <span className="card-captions-type">
            {productUnit ? productUnit : ""}
          </span>
        </p>
      </div>
      <div className="card-price">
        <h3 className="card-title">السعر</h3>
        <div className="card-price-item">
          <p className="price-item">من</p>
          {priceBefore ? (
            <p className="card-captions">
              {`${Math.trunc(priceBefore)}`}{" "}
              <span className="card-captions-type">ريال</span>
            </p>
          ) : (
            <p className="card-captions"></p>
          )}
        </div>
        <div className="card-price-item">
          <p className="price-item">الي</p>
          {priceAfter ? (
            <p className="card-captions">
              {`${Math.trunc(priceAfter)}`}{" "}
              <span className="card-captions-type">ريال</span>
            </p>
          ) : (
            <p className="card-captions"></p>
          )}
        </div>
      </div>
      <div>
        {" "}
        <ButtonDelete currentItem={currentItem} />
        <ButtonEdit to="/edit-merchant" onClick={onClick} />
      </div>
    </div>
  );
};

export default CardProduct;
