import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useState } from "react";
import "./Input.scss";
const Input = ({
  className = "",
  placeholder,
  type = "text",
  inputIcon,
  inputKey,
  hidden = false,
}) => {
  const { values, handleChange, handleBlur } = useFormikContext();
  return (
    <div className={`custom-input ${className}`}>
      <img className="input-icon" src={inputIcon} alt="" />
      <input
        type={type}
        className="input"
        placeholder={placeholder}
        id={inputKey}
        name={inputKey}
        value={values[inputKey]}
        onChange={handleChange}
        onBlur={handleBlur}
        hidden={hidden}
      />
      {/* {errors?.inputError && touched?.inputError && (
        <p className="error">{errors?.inputError}</p>
      )} */}
      <p className="error">
        <ErrorMessage name={inputKey} />
      </p>
    </div>
  );
};

export default Input;
