import React, { useContext, useState } from "react";
import deleteProductApi from "../../../apis/deleteProductApi";
import { Context } from "../../../context/Context";
import ConfirmBox from "../../ConfirmBox/ConfirmBox";
import "./ButtonDelete.scss";

const ButtonDelete = (props) => {
  const { getToggle } = useContext(Context);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [currentItemId, setCurrentItemId] = useState("");
  const getCurrentItemId = (id) => {
    setIsConfirming(true);
    setCurrentItemId(id);
  };

  return (
    <>
      <button
        className="btn-delete"
        onClick={() => getCurrentItemId(props.currentItem)}
      >
        <span className="btn-text"> مسح</span>
        <i className="fa-solid fa-trash btn-icon"></i>
      </button>
      {isConfirming && (
        <ConfirmBox
          exitBtn={() => setIsConfirming(false)}
          title={"مسح المنتج"}
          caption="هل تريد مسح المنتج"
          confirmText="نعم, امسح المنتج"
          confirmYes={() =>
            deleteProductApi(
              currentItemId,
              setIsDeletingProduct,
              setIsConfirming,
              getToggle
            )
          }
          isLoading={isDeletingProduct}
        />
      )}
    </>
  );
};

export default ButtonDelete;
