import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { Context } from "../../context/Context";
import { productSchema } from "../../schemas/productSchema";
import Input from "../Input/Input";
import Checkbox from "../Input/Checkbox/Checkbox";
import FormContainer from "../FormContainer/FormContainer";
import Form from "./Form";
import editProductApi from "../../apis/editProductApi";
const EditProductForm = ({
  category_id,
  categoryImg,
  enCategory,
  arCategory,
}) => {
  const { getToggle, currentItem, setCurrentItem } = useContext(Context);
  const [isEditProduct, setIsEditProduct] = useState(false);
  const {
    id,
    name_ar,
    price_before,
    price_after,
    menu_active,
    quantity,
    unit,
  } = currentItem;
  const initialValues = {
    id: id,
    name_ar: name_ar,
    price_before: price_before,
    price_after: price_after,
    menu_active: menu_active,
    category_id: category_id,
    quantity: quantity,
    unit: unit,
  };
  return (
    <FormContainer
      arCategory={arCategory}
      enCategory={enCategory}
      categoryImg={categoryImg}
    >
      {id ? (
        <Formik
          initialValues={initialValues}
          validationSchema={productSchema}
          onSubmit={(values) =>
            editProductApi(values, setIsEditProduct, getToggle, setCurrentItem)
          }
        >
          <Form isLoading={isEditProduct} btnText="تعديل">
            <Input placeholder="اسم المنتج" inputKey="name_ar" />
            <Input placeholder="السعر من" inputKey="price_before" />
            <Input placeholder="السعر الي" inputKey="price_after" />
            <Input placeholder="الكمية" inputKey="quantity" />
            <Input placeholder="جرام او كليو او طن ...الخ" inputKey="unit" />
            <Checkbox inputKey="menu_active" />
          </Form>
        </Formik>
      ) : (
        <div className="chosse-prodcut-error">
          برجاء اعادة اختيار منتج ليتم تعديله
        </div>
      )}
    </FormContainer>
  );
};
export default EditProductForm;
