import React from "react";
import fruitBigImg from "../../../images/fruitBigImg.svg";
import "./AddFruit.scss";
import AddProductForm from "../../../components/Form/AddProductForm";
const AddFruit = () => {
  return (
    <AddProductForm
      categoryImg={fruitBigImg}
      arCategory="الفاكهة"
      enCategory="Fruits"
      category_id={2}
    />
  );
};
export default AddFruit;
