import React from "react";
import LoginModel from "../../components/LoginModel/LoginModel";
const Login = () => {
  return (
    <>
      <LoginModel />
    </>
  );
};

export default Login;
