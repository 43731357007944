import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import getAdmainProfileApi from "../apis/getAdmainProfileApi";
import getMerchantsApi from "../apis/getMerchantsApi";
import getProductsApi from "../apis/getProductsApi";

export const Context = createContext(0);
const ContextProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("userToken") || "");
  const navigate = useNavigate();
  const navigateTo = (navigatePathname) => {
    navigate(navigatePathname);
  };
  const location = useLocation();
  const [currentCategory, setCurrentCategory] = useState(
    location.pathname.slice(1)
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentpage] = useState(searchParams.get("page"));
  useEffect(() => {
    setCurrentCategory(location.pathname.slice(1));
  }, [location.pathname]);
  const dir = "rtl";
  const lang = "ar";

  // const [dir, setDir] = useState("rtl");
  // const [lang, setLang] = useState("ar");
  // useEffect(() => {
  //   if (localStorage.getItem("dir")) {
  //     setDir(localStorage.getItem("dir"));
  //     document.body.style.direction = dir;
  //   } else {
  //     localStorage.setItem("dir", "rtl");
  //   }
  //   if (dir === "rtl") {
  //     setLang("ar");
  //   } else {
  //     setLang("en");
  //   }
  // }, [dir]);
  //////////////////////////////////
  useEffect(() => {
    document.documentElement.setAttribute("lang", lang);
    document.body.style.direction = dir;
  });
  ///////////////////////////
  axios.defaults.baseURL = "https://mobasher.almaharh.com/api";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["lang"] = lang;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  //////////////////////////
  const [getetSearchPapers, setGetSearchPapers] = useState([]);
  const [getSearchedPapersLength, setgetSearchedPapersLength] = useState([]);
  const [getetSearchFruits, setGetSearchFruits] = useState([]);
  const [getSearchedFruitsLength, setgetSearchedFruitsLength] = useState([]);
  const [getetSearchVegetables, setGetSearchVegetables] = useState([]);
  const [getSearchedVegetablesLength, setgetSearchedVegetablesLength] =
    useState([]);
  const [getetSearchMerchants, setGetSearchMerchants] = useState([]);
  const [getSearchedMerchantsLength, setgetSearchedMerchantsLength] = useState(
    []
  );

  const [adminProfileData, setAdminProfileData] = useState("");
  const [isLoadingProfile, SetIsLoadingProfile] = useState(false);
  const [merchantData, setMerchantData] = useState([]);
  const [isMerchantsDataLoading, setIsMerchantsDataLoading] = useState(false);
  const [vegetablesData, setVegetablesData] = useState([]);
  const [isVegetablesDataLoading, setIsVegetablesDataLoading] = useState(false);
  const [fruitsData, setFruitsData] = useState([]);
  const [isFruitsDataLoading, setIsFruitsDataLoading] = useState(false);
  const [papersData, setPapersData] = useState([]);
  const [isPapersDataLoading, setIsPapersDataLoading] = useState(false);
  const [reCallApiLoading, setReCallApiLoading] = useState(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [isAddEditProduct, setIsAddEditProduct] = useState(false);
  const [vegetablesPaginationLength, setVegetablesPaginationLength] = useState(
    []
  );

  const [fruitsPaginationLength, setFruitsPaginationLength] = useState([]);
  const [papersPaginationLength, setPapersPaginationLength] = useState([]);
  const [merchantsPaginationLength, setMerchantsPaginationLength] = useState(
    []
  );

  const [currentItem, setCurrentItem] = useState("");
  const [toggle, SetToggle] = useState(false);
  const getToggle = () => {
    if (toggle) {
      SetToggle(false);
    } else {
      SetToggle(true);
    }
  };
  const [isClicked, setIsClicked] = useState(false);
  const { is_admin } = adminProfileData;
  useEffect(() => {
    if (token) {
      if (merchantsPaginationLength?.length == 0) {
        getMerchantsApi(
          setMerchantData,
          setIsMerchantsDataLoading,
          1,
          setMerchantsPaginationLength,
          is_admin
        );
      } else {
        getMerchantsApi(
          setMerchantData,
          setIsMerchantsDataLoading,
          currentPage,
          setMerchantsPaginationLength,
          is_admin
        );
      }
      /////////////////////////////////
      if (vegetablesPaginationLength?.length == 0) {
        getProductsApi(
          setVegetablesData,
          setIsVegetablesDataLoading,
          1,
          1,
          setVegetablesPaginationLength
        );
      } else {
        getProductsApi(
          setVegetablesData,
          setIsVegetablesDataLoading,
          1,
          currentPage,
          setVegetablesPaginationLength
        );
      }
      ////////////////////////////////////
      if (fruitsPaginationLength?.length == 0) {
        getProductsApi(
          setFruitsData,
          setIsFruitsDataLoading,
          2,
          1,
          setFruitsPaginationLength
        );
      } else {
        getProductsApi(
          setFruitsData,
          setIsFruitsDataLoading,
          2,
          currentPage,
          setFruitsPaginationLength
        );
      }
      //////////////////////////////////////
      if (papersPaginationLength?.length == 0) {
        getProductsApi(
          setPapersData,
          setIsPapersDataLoading,
          3,
          1,
          setPapersPaginationLength
        );
      } else {
        getProductsApi(
          setPapersData,
          setIsPapersDataLoading,
          3,
          currentPage,
          setPapersPaginationLength
        );
      }
    }
  }, [token, is_admin]);

  useEffect(() => {
    if (token) {
      getAdmainProfileApi(setAdminProfileData, setReCallApiLoading);
      ////////////////////////
      if (
        currentCategory == "merchants" ||
        currentCategory == "add-merchant" ||
        currentCategory == "edit-merchant"
      ) {
        if (merchantsPaginationLength?.length == 0) {
          getMerchantsApi(
            setMerchantData,
            setReCallApiLoading,
            1,
            setMerchantsPaginationLength,
            is_admin
          );
          return;
        } else {
          getMerchantsApi(
            setMerchantData,
            setReCallApiLoading,
            currentPage,
            setMerchantsPaginationLength,
            is_admin
          );
          return;
        }
      }
      ////////////////////////
      if (
        currentCategory == "vegetables" ||
        currentCategory == "vegetables/add-product" ||
        currentCategory == "vegetables/edit-product"
      ) {
        if (vegetablesPaginationLength?.length == 0) {
          getProductsApi(
            setVegetablesData,
            setReCallApiLoading,
            1,
            1,
            setVegetablesPaginationLength
          );
          return;
        } else {
          getProductsApi(
            setVegetablesData,
            setReCallApiLoading,
            1,
            currentPage,
            setVegetablesPaginationLength
          );
          return;
        }
      } else if (
        currentCategory == "fruits" ||
        currentCategory == "fruits/add-product" ||
        currentCategory == "fruits/edit-product"
      ) {
        if (fruitsPaginationLength?.length == 0) {
          getProductsApi(
            setFruitsData,
            setReCallApiLoading,
            2,
            1,
            setFruitsPaginationLength
          );
          return;
        } else {
          getProductsApi(
            setFruitsData,
            setReCallApiLoading,
            2,
            currentPage,
            setFruitsPaginationLength
          );
          return;
        }
      } else if (
        currentCategory == "papers" ||
        currentCategory == "papers/add-product" ||
        currentCategory == "papers/edit-product"
      ) {
        if (papersPaginationLength?.length == 0) {
          getProductsApi(
            setPapersData,
            setReCallApiLoading,
            3,
            1,
            setPapersPaginationLength
          );
          return;
        } else {
          getProductsApi(
            setPapersData,
            setReCallApiLoading,
            3,
            currentPage,
            setPapersPaginationLength
          );
          return;
        }
      }
    }
  }, [
    toggle,
    token,
    setPapersData,
    setFruitsData,
    setVegetablesData,
    is_admin,
  ]);
  useEffect(() => {
    return () => {
      setGetSearchFruits([]);
      setgetSearchedFruitsLength([]);
      setGetSearchVegetables([]);
      setgetSearchedVegetablesLength([]);
      setGetSearchPapers([]);
      setgetSearchedPapersLength([]);
      setGetSearchMerchants([]);
      setgetSearchedMerchantsLength([]);
    };
  }, [papersData, vegetablesData, fruitsData, currentCategory, merchantData]);

  return (
    <Context.Provider
      value={{
        isClicked,
        setIsClicked,
        getetSearchPapers,
        setGetSearchPapers,
        getSearchedPapersLength,
        setgetSearchedPapersLength,
        getSearchedFruitsLength,
        setgetSearchedFruitsLength,
        getetSearchFruits,
        setGetSearchFruits,
        getetSearchVegetables,
        setGetSearchVegetables,
        getSearchedVegetablesLength,
        setgetSearchedVegetablesLength,
        currentCategory,
        token,
        setToken,
        getToggle,
        searchParams,
        isLoadingProfile,
        adminProfileData,
        setAdminProfileData,
        merchantData,
        setMerchantData,
        isMerchantsDataLoading,
        setIsMerchantsDataLoading,
        getetSearchMerchants,
        setGetSearchMerchants,
        getSearchedMerchantsLength,
        setgetSearchedMerchantsLength,
        merchantsPaginationLength,
        setMerchantsPaginationLength,
        isVegetablesDataLoading,
        setIsVegetablesDataLoading,
        vegetablesData,
        setVegetablesData,
        vegetablesPaginationLength,
        setVegetablesPaginationLength,
        isFruitsDataLoading,
        setIsFruitsDataLoading,
        fruitsData,
        setFruitsData,
        fruitsPaginationLength,
        setFruitsPaginationLength,
        isPapersDataLoading,
        setIsPapersDataLoading,
        papersData,
        setPapersData,
        papersPaginationLength,
        isDeletingProduct,
        setIsDeletingProduct,
        isAddEditProduct,
        setIsAddEditProduct,
        currentItem,
        setCurrentItem,
        navigateTo,
        navigate,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default ContextProvider;
