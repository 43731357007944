import axios from "axios";
import { toast } from "react-toastify";
const addProductApi = async (values, loading, toggle) => {
  loading(true);
  // const formDate = new FormData();
  // if(values.menu){
  //   formDate.append("",1)
  // }else{}
  try {
    let response = await axios.post(`/save-product`, values);
    loading(false);
    if (response?.status === 200 && response?.data?.success) {
      toggle();
      toast.success("تم اضافة المنتج بنجاح");
      return;
    } else {
      toast.error(response?.data?.message || "Something went wrong");
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default addProductApi;
