import * as yup from "yup";
// const passwordRules = /^(?=.*\d) (?=.*[a-z]) (?=.*[A-Z]).{5,}$/;
// min 5 Characters, 1 upper case letter, 1 lower case letter, 1 numeric digit
export const changePasswordSchema = yup.object().shape({
  old_password: yup.string().required("من فضلك ادخل  كلمة المرور"),
  new_password: yup.string().required("من فضلك ادخل  كلمة المرور"),
  confirm_new_password: yup
    .string()
    .required("من فضلك ادخل  كلمة المرور")
    .oneOf([yup.ref("new_password"), null], "من فضلك ادخل كلمة مطابقة"),
});
