import React, { useContext, useState } from "react";
import "./ShowFruits.scss";
import Category from "../../../components/Category/Category";
import fruitBigImg from "../../../images/fruitBigImg.svg";
import { Context } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import getProductsApi from "../../../apis/getProductsApi";

const ShowFruits = () => {
  const {
    currentPage,
    searchParams,
    getetSearchFruits,
    setGetSearchFruits,
    getSearchedFruitsLength,
    setgetSearchedFruitsLength,
    fruitsData,
    isFruitsDataLoading,
    fruitsPaginationLength,
    setIsFruitsDataLoading,
  } = useContext(Context);
  const [text, setText] = useState(searchParams.get("name") || "");

  // const [pL, setPL] = useState();

  const navigate = useNavigate();
  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setText(event.target.value);
  };
  const getSearchProducts = () => {
    navigate({
      search: `name=${text}`,
    });
    getProductsApi(
      setGetSearchFruits,
      setIsFruitsDataLoading,
      2,
      currentPage,
      setgetSearchedFruitsLength,
      text
    );
  };
  const { data: contextData } = fruitsData || {};
  const { data: searchData } = getetSearchFruits || {};

  return (
    <>
      <Category
        tableData={searchData || contextData}
        arCategory="الفاكهة"
        enCategory="Fruits"
        categoryImg={fruitBigImg}
        isLodingData={isFruitsDataLoading}
        paginationPages={
          searchData ? getSearchedFruitsLength : fruitsPaginationLength
        }
        onClick={() => getSearchProducts()}
        onChange={handleChange}
        defaultValue={text}
        searchText={text}
        to="add-product"
      />
    </>
  );
};

export default ShowFruits;
