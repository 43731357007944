import React from "react";
import { Link } from "react-router-dom";
import iconBack from "../../../icons/iconBack.svg";
const ButtonBack = () => {
  return (
    <Link className="link-style" to={"/"}>
      <button className="float-icon floating-logout-btn" type="button">
        <img src={iconBack} className="float-icon" alt="" />
      </button>
    </Link>
  );
};

export default ButtonBack;
