import React, { useContext } from "react";
import { Context } from "../../context/Context";
import ButtonDelete from "../Buttons/ButtonDelete/ButtonDelete";
import ButtonEdit from "../Buttons/ButtonEdit/ButtonEdit";
import CardProduct from "../Card/CardProduct";
import Pagination from "../Pagination/Pagination";
import "./Table.scss";

const Table = ({ tableData, paginationPages, searchText }) => {
  const { setCurrentItem } = useContext(Context);
  return (
    <>
      <div className="table-wrapper">
        {tableData?.length ? (
          tableData?.map((item, i) => (
            <>
              <CardProduct
                key={item?.id}
                productQty={item?.quantity}
                productUnit={item?.unit}
                priceBefore={item?.price_before}
                priceAfter={item?.price_after}
                productName={item?.name}
                className="card-table "
                currentItem={item?.id}
                onClick={() => setCurrentItem(item || {})}
              />
            </>
          ))
        ) : (
          <p className="card-no-product  ">لايوجد منتجات</p>
        )}

        <table className="table">
          <tbody>
            {tableData?.length ? (
              tableData.map((item, i) => (
                <tr
                  key={i}
                  className={
                    i % 2 === 0 ? "table-row" : "table-row table-row-even"
                  }
                >
                  <td className="first-item">{i + 1} </td>
                  <td className="table-product">
                    {item?.name ? item?.name : ""}
                  </td>
                  <td className="table-item">
                    {item?.price_before
                      ? `${Math.trunc(item?.price_before)} ريال`
                      : ""}
                  </td>
                  <td className="table-item">
                    {item?.price_after
                      ? `${Math.trunc(item?.price_after)} ريال`
                      : ""}
                  </td>
                  <td className="table-item">
                    {item?.quantity ? Math.trunc(item?.quantity) : ""}
                    {item?.unit ? item?.unit : ""}
                  </td>
                  <td className="table-btn-delete">
                    <ButtonDelete currentItem={item?.id} />
                  </td>
                  <td className="last-item">
                    <ButtonEdit
                      to="edit-product"
                      onClick={() => setCurrentItem(item || {})}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="table-row">
                <td className="table-product">لايوجد منتجات</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {paginationPages?.length > 1 && (
        <Pagination paginationPages={paginationPages} searchText={searchText} />
      )}
    </>
  );
};

export default Table;
