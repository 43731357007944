import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { Context } from "../../context/Context";
import { productSchema } from "../../schemas/productSchema";
import Input from "../Input/Input";
import Checkbox from "../Input/Checkbox/Checkbox";
import FormContainer from "../FormContainer/FormContainer";
import Form from "./Form";
import addProductApi from "../../apis/addProductApi";

const AddProductForm = ({
  category_id,
  categoryImg,
  enCategory,
  arCategory,
}) => {
  const { getToggle } = useContext(Context);
  const [isAddProduct, setIsAddProduct] = useState(false);
  const initialValues = {
    name_ar: "",
    price_before: "",
    price_after: "",
    menu_active: 0,
    category_id: category_id,
    quantity: "",
    unit: "",
  };

  return (
    <FormContainer
      arCategory={arCategory}
      enCategory={enCategory}
      categoryImg={categoryImg}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={productSchema}
        onSubmit={(values) => addProductApi(values, setIsAddProduct, getToggle)}
      >
        <Form isLoading={isAddProduct} btnText="اضافة">
          <Input placeholder="اسم المنتج" inputKey="name_ar" />
          <Input placeholder="السعر من" inputKey="price_before" />
          <Input placeholder="السعر الي" inputKey="price_after" />
          <Input placeholder="الكمية" inputKey="quantity" />
          <Input placeholder="جرام او كليو او طن ...الخ" inputKey="unit" />

          <Checkbox inputKey="menu_active" />
        </Form>
      </Formik>
    </FormContainer>
  );
};
export default AddProductForm;
