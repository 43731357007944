import React from "react";

import paperBigImg from "../../../images/paperBigImg.svg";
import "./EditPaper.scss";
import EditProductForm from "../../../components/Form/EditProductForm";

const EditPaper = () => {
  return (
    <EditProductForm
      categoryImg={paperBigImg}
      arCategory="ورقيات"
      enCategory="Papers"
      category_id={3}
    />
  );
};
export default EditPaper;
