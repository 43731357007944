import axios from "axios";
import { toast } from "react-toastify";
const changePassword = async (values, loading, navigateTo) => {
  loading(true);
  try {
    let response = await axios.post(`/change-password`, values);
    loading(false);
    if (response?.status === 200 && response?.data?.success) {
      toast.success("تم تعديل كلمة المرور بنجاح");
      navigateTo("/");
      return;
    } else {
      toast.error(response?.data?.message || "Something went wrong");
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default changePassword;
