import React from "react";
import fruitBigImg from "../../../images/fruitBigImg.svg";
import EditProductForm from "../../../components/Form/EditProductForm";
const EditFruit = () => {
  return (
    <EditProductForm
      categoryImg={fruitBigImg}
      arCategory="الفاكهة"
      enCategory="Fruit"
      category_id={2}
    />
  );
};

export default EditFruit;
