import React from "react";
import { Link } from "react-router-dom";
import "./ProfileBox.scss";

const ProfileBox = ({ className, icon, title, to, img }) => {
  return (
    <Link className={`link-style profile-Box home-box ${className}`} to={to}>
      <div>
        <img src={img} alt="" />
        {/* <i className={`fa-regular fa-user profile-icon ${icon} `}></i> */}
        <p className="profile-title">{title}</p>
      </div>
    </Link>
  );
};

export default ProfileBox;
