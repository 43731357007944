import React from "react";
import "./NavBoxes.scss";
import CategoryBox from "../../components/Nav/CategoryBox/CategoryBox";
// import ProfileBox from "../../components/Nav/ProfileBox/ProfileBox";

import vegetableBigImg from "../../images/vegetableBigImg.svg";
import fruitBigImg from "../../images/fruitBigImg.svg";
import paperBigImg from "../../images/paperBigImg.svg";
import settingsImg from "../../images/settings.svg";

import Logout from "../Logout/Logout";

const NavBoxes = () => {
  return (
    <>
      <Logout />
      <div className="nav-boxes">
        <CategoryBox
          arCategory="الخضار"
          enCategory="Vegetables"
          categoryImg={vegetableBigImg}
          to="/vegetables"
        />
        <CategoryBox
          arCategory="الفاكهة"
          enCategory="Fruits"
          categoryImg={fruitBigImg}
          to="/fruits"
        />
        <CategoryBox
          arCategory="ورقيات"
          enCategory="Papers"
          categoryImg={paperBigImg}
          to="/papers"
        />
        <CategoryBox
          arCategory="اعدادات"
          enCategory="Settings"
          categoryImg={settingsImg}
          to="/settings"
          className="box-settings"
          titleClassName="title-style"
        />
      </div>
    </>
  );
};

export default NavBoxes;
