import React from "react";
import "./Model.scss";
import mubashirLogo from "../../images/mubashirLogo.svg";

const Model = ({ title, caption }) => {
  return (
    <div className="model-wrapper">
      <div className="logo-wrapper">
        <img src={mubashirLogo} alt="" />
      </div>
      <div className="model-head">
        <h3 className="model-title">{title}</h3>
        <p className="model-caption"> {caption}</p>
      </div>
    </div>
  );
};

export default Model;
