import axios from "axios";
import { toast } from "react-toastify";
const deleteMerchantApi = async (id, loading, setIsConfirming, toggle) => {
  loading(true);
  try {
    let response = await axios.post(`/user-activation`, {
      user_id: id,
    });
    loading(false);
    if (response?.status === 200 && response?.data?.success) {
      toggle();
      toast.success("تم حذف التاجر بنجاح");
      setIsConfirming(false);
      return;
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
    setIsConfirming(false);
    return;
  }
};
export default deleteMerchantApi;
