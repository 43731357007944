import React from "react";
import CategoryHead from "../CategoryHead/CategoryHead";
import Container from "../Container/Container";
import LoadingTable from "../Table/LoadingTable/LoadingTable";
import TableMerchants from "../Table/TableMerchants";

const MerchantsTable = ({
  tableData,
  arCategory,
  enCategory,
  categoryImg,
  isLodingData,
  paginationPages,
  onClick,
  onChange,
  defaultValue,
  searchText,
}) => {
  return (
    <>
      <Container>
        <CategoryHead
          arCategory={arCategory}
          enCategory={enCategory}
          categoryImg={categoryImg}
          isLodingData={isLodingData}
          onClick={onClick}
          onChange={onChange}
          defaultValue={defaultValue}
          to="/add-merchant"
          btnText="اضافة تاجر"
          addIcon={"fa-solid fa-plus"}
        />
        {isLodingData ? (
          <LoadingTable />
        ) : (
          <TableMerchants
            tableData={tableData}
            paginationPages={paginationPages}
            searchText={searchText}
          />
        )}
      </Container>
    </>
  );
};

export default MerchantsTable;
