import axios from "axios";
import { toast } from "react-toastify";
import getAdmainProfileApi from "./getAdmainProfileApi";
const updateProfile = async (values, loading, navigateTo, updatedProfile) => {
  loading(true);
  try {
    let response = await axios.post(`/update-profile`, values);
    loading(false);
    if (response?.status === 200 && response?.data?.success) {
      toast.success("تم تعديل المعلومات بنجاح");
      navigateTo("/");
      updatedProfile(response?.data?.data);
      return;
    } else {
      toast.error(response?.data?.message || "Something went wrong");
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default updateProfile;
