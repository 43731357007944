import React, { useContext } from "react";
import { Context } from "../../context/Context";
import Logout from "../Logout/Logout";
import NavSide from "../NavSide/NavSide";
import "./Container.scss";
const Container = ({ children, className }) => {
  const { isClicked } = useContext(Context);
  return (
    <>
      <Logout />
      <div
        className={isClicked ? "container container-overflow " : "container"}
      >
        <NavSide />
        <div className={`category-container ${className}`}>{children}</div>
      </div>
    </>
  );
};

export default Container;
