import { Formik } from "formik";
import React, { useContext, useState } from "react";
import changePassword from "../../../apis/changePassword";
import ButtonBack from "../../../components/Buttons/ButtonBack/ButtonBack";
import Form from "../../../components/Form/Form";
import InputPassword from "../../../components/Input/InputPassword";
import Model from "../../../components/Model/Model";
import { Context } from "../../../context/Context";
import { changePasswordSchema } from "../../../schemas/changePasswordSchema";
import "./EditProfile.scss";
const EditProfile = () => {
  const [isLodingEditProfile, setIsLodingEditProfile] = useState(false);
  const { navigateTo } = useContext(Context);
  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  };
  return (
    <>
      <div className="edit-profile-wrapper">
        <ButtonBack />
        <Model title="الملف الشخصي" caption="تغيير كلمة المرور" />
        <Formik
          initialValues={initialValues}
          validationSchema={changePasswordSchema}
          onSubmit={(values) =>
            changePassword(values, setIsLodingEditProfile, navigateTo)
          }
        >
          <Form isLoading={isLodingEditProfile} btnText="تعديل">
            <InputPassword
              placeholder="الرقم السري القديم"
              inputKey="old_password"
            />
            <InputPassword
              placeholder="الرقم السري الجديد"
              inputKey="new_password"
            />
            <InputPassword
              placeholder="إعادة كتابه الرقم السري الجديد"
              inputKey="confirm_new_password"
            />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default EditProfile;
