import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { Context } from "../../context/Context";
import { profileSchema } from "../../schemas/profileSchema";
import Input from "../Input/Input";
import Form from "./Form";
import Model from "../Model/Model";
import ButtonBack from "../Buttons/ButtonBack/ButtonBack";
import addProfileApi from "../../apis/addProfileApi";

const AddProfileForm = ({}) => {
  const { getToggle } = useContext(Context);
  const [isAddProfile, setIsAddProfile] = useState(false);
  const initialValues = {
    name: "",
    phone: "",
    password: "",
  };

  return (
    <>
      <ButtonBack />
      <Model
        title="إضافة تاجر"
        caption="يرجي اضافه كافة البيانات المطلوبة لاضافه التجار"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={profileSchema}
        onSubmit={(values) => addProfileApi(values, setIsAddProfile, getToggle)}
      >
        <Form isLoading={isAddProfile} btnText="اضافة">
          <Input
            placeholder="اسم المستخدم ( باللغة الإنجليزية )"
            inputKey="name"
          />
          <Input placeholder="الرقم السري" inputKey="password" />
          <Input
            placeholder="رقم جوال الواتس أب الخاص بالتاجر"
            inputKey="phone"
          />
        </Form>
      </Formik>
    </>
  );
};
export default AddProfileForm;
