import React from "react";
import NavBoxes from "../../components/Nav/NavBoxes";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <NavBoxes />
    </>
  );
};

export default Home;
