import React, { useContext, useState } from "react";
import "./ShowPapers.scss";
import Category from "../../../components/Category/Category";
import paperBigImg from "../../../images/paperBigImg.svg";
import { Context } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import getProductsApi from "../../../apis/getProductsApi";
const ShowPapers = () => {
  const {
    isPapersDataLoading,
    papersData,
    papersPaginationLength,
    currentPage,
    searchParams,
    getetSearchPapers,
    setGetSearchPapers,
    getSearchedPapersLength,
    setgetSearchedPapersLength,
    setIsPapersDataLoading,
  } = useContext(Context);
  const [text, setText] = useState(searchParams.get("name") || "");
  const navigate = useNavigate();
  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setText(event.target.value);
  };
  const getSearchProducts = () => {
    navigate({
      search: `name=${text}`,
    });
    getProductsApi(
      setGetSearchPapers,
      setIsPapersDataLoading,
      3,
      currentPage,
      setgetSearchedPapersLength,
      text
    );
  };

  const { data: contextData } = papersData || {};
  const { data: searchData } = getetSearchPapers || {};
  return (
    <>
      <Category
        tableData={searchData || contextData}
        arCategory="ورقيات"
        enCategory="Papers"
        categoryImg={paperBigImg}
        isLodingData={isPapersDataLoading}
        paginationPages={
          searchData ? getSearchedPapersLength : papersPaginationLength
        }
        onClick={() => getSearchProducts()}
        onChange={handleChange}
        defaultValue={text}
        searchText={text}
        to="add-product"
      />
    </>
  );
};

export default ShowPapers;
