import React, { useContext } from "react";
import "./SearchInput.scss";
import iconSearch from "../../../icons/iconSearch.svg";
import { Context } from "../../../context/Context";

const SearchInput = ({ onClick, onChange, defaultValue }) => {
  const {} = useContext(Context);

  return (
    <div className="search-wrapper">
      <input
        type="text"
        className="search-input"
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <button className="search-btn" type="button" onClick={onClick}>
        <img src={iconSearch} alt="" className="search-icon" />
      </button>
    </div>
  );
};

export default SearchInput;
