import React, { useContext, useState } from "react";
import merchants from "../../../images/merchants.svg";

import { Context } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import MerchantsTable from "../../../components/Merchant/MerchantsTable";
import getMerchantsApi from "../../../apis/getMerchantsApi";
const ShowMerchants = () => {
  const {
    adminProfileData,
    currentPage,
    searchParams,
    merchantData, //
    isMerchantsDataLoading, //
    setIsMerchantsDataLoading, //
    getetSearchMerchants, //
    setGetSearchMerchants, //
    getSearchedMerchantsLength, //
    setgetSearchedMerchantsLength, //
    merchantsPaginationLength, //
  } = useContext(Context);
  const [text, setText] = useState(searchParams.get("name") || "");

  // const [pL, setPL] = useState();

  const navigate = useNavigate();
  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setText(event.target.value);
  };
  const getSearchMerchants = () => {
    navigate({
      search: `name=${text}`,
    });
    getMerchantsApi(
      setGetSearchMerchants,
      setIsMerchantsDataLoading,
      currentPage,
      setgetSearchedMerchantsLength,
      adminProfileData?.is_admin,
      text
    );
  };
  const { data: contextData } = merchantData || {};
  const { data: searchData } = getetSearchMerchants || {};

  return (
    <>
      <MerchantsTable
        tableData={searchData || contextData}
        arCategory="التجار"
        enCategory="Merchants"
        categoryImg={merchants}
        isLodingData={isMerchantsDataLoading}
        paginationPages={
          searchData ? getSearchedMerchantsLength : merchantsPaginationLength
        }
        onClick={() => getSearchMerchants()}
        onChange={handleChange}
        defaultValue={text}
        searchText={text}
      />
    </>
  );
};

export default ShowMerchants;
