import React, { useContext, useState } from "react";
import "./ShowVegetables.scss";
import Category from "../../../components/Category/Category";
import vegetableBigImg from "../../../images/vegetableBigImg.svg";
import { Context } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import getProductsApi from "../../../apis/getProductsApi";

const ShowVegetables = () => {
  const {
    vegetablesData,
    isVegetablesDataLoading,
    vegetablesPaginationLength,
    currentPage,
    searchParams,
    getetSearchVegetables,
    setGetSearchVegetables,
    getSearchedVegetablesLength,
    setgetSearchedVegetablesLength,
    setIsVegetablesDataLoading,
  } = useContext(Context);
  const [text, setText] = useState(searchParams.get("name") || "");
  const navigate = useNavigate();
  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setText(event.target.value);
  };
  const getSearchProducts = () => {
    navigate({
      search: `name=${text}`,
    });
    getProductsApi(
      setGetSearchVegetables,
      setIsVegetablesDataLoading,
      1,
      currentPage,
      setgetSearchedVegetablesLength,
      text
    );
  };

  const { data: contextData } = vegetablesData || {};
  const { data: searchData } = getetSearchVegetables || {};
  return (
    <>
      <Category
        tableData={searchData || contextData}
        arCategory="الخضار"
        enCategory="Vegetables"
        categoryImg={vegetableBigImg}
        isLodingData={isVegetablesDataLoading}
        paginationPages={
          searchData ? getSearchedVegetablesLength : vegetablesPaginationLength
        }
        onClick={() => getSearchProducts()}
        onChange={handleChange}
        defaultValue={text}
        searchText={text}
        to="add-product"
      />
    </>
  );
};

export default ShowVegetables;
