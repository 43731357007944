import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { Context } from "../../context/Context";
import Input from "../Input/Input";
import Form from "./Form";
import editMerchantApi from "../../apis/editMerchantApi";
import Model from "../Model/Model";
import ButtonBack from "../Buttons/ButtonBack/ButtonBack";
import { updateProfileSchema } from "../../schemas/updateProfileSchema";

const EditMerchantForm = ({}) => {
  const { getToggle, currentItem, setCurrentItem } = useContext(Context);
  const [isEditMerchants, setIsEditMerchant] = useState(false);
  const { id, name, phone } = currentItem;
  const initialValues = {
    id: id,
    name: name,
    phone: phone,
  };
  return (
    // <FormContainer
    //   arCategory={arCategory}
    //   enCategory={enCategory}
    //   categoryImg={categoryImg}
    // >
    //   {id ? (
    //     <Formik
    //       initialValues={initialValues}
    //       validationSchema={productSchema}
    //       onSubmit={(values) =>
    //         editProductApi(values, setIsEditProduct, getToggle, setCurrentItem)
    //       }
    //     >
    //       <Form isLoading={isEditProduct} btnText="تعديل">
    //         <Input placeholder="اسم المنتج" inputKey="name_ar" />
    //         <Input placeholder="السعر من" inputKey="price_before" />
    //         <Input placeholder="السعر الي" inputKey="price_after" />
    //         <Input placeholder="الكمية" inputKey="quantity" />
    //         <Checkbox inputKey="menu_active" />
    //       </Form>
    //     </Formik>
    //   ) : (
    //     <div className="chosse-prodcut-error">
    //       برجاء اعادة اختيار منتج ليتم تعديله
    //     </div>
    //   )}
    // </FormContainer>
    <>
      <ButtonBack />
      <Model
        title="تعديل تاجر"
        caption="يرجي اضافه كافة البيانات المطلوبة لتعديل التجار"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={updateProfileSchema}
        onSubmit={(values) =>
          editMerchantApi(values, setIsEditMerchant, getToggle, setCurrentItem)
        }
      >
        <Form isLoading={isEditMerchants} btnText="تعديل">
          <Input
            placeholder="اسم المستخدم ( باللغة الإنجليزية )"
            inputKey="name"
          />
          <Input
            placeholder="رقم جوال الواتس أب الخاص بالتاجر"
            inputKey="phone"
          />
        </Form>
      </Formik>
    </>
  );
};
export default EditMerchantForm;
