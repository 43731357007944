import React from "react";
import { Link } from "react-router-dom";
import "./ButtonAdd.scss";

const ButtonAdd = ({ to, btnText, addIcon = "" }) => {
  return (
    <Link className="link-style" to={to}>
      <button className="btn-add" type="button">
        <span className="btn-text">{btnText}</span>
        <i className={addIcon}></i>
      </button>
    </Link>
  );
};

export default ButtonAdd;
