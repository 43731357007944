import React from "react";
import vegetableBigImg from "../../../images/vegetableBigImg.svg";
import "./AddVegetable.scss";
import AddProductForm from "../../../components/Form/AddProductForm";
const AddVegetable = () => {
  return (
    <AddProductForm
      categoryImg={vegetableBigImg}
      arCategory="الخضار"
      enCategory="Vegetables"
      category_id={1}
    />
  );
};

export default AddVegetable;
