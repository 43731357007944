import { useFormikContext } from "formik";
import React from "react";
import ButtonMain from "../Buttons/ButtonMain/ButtonMain";
import "./Form.scss";

const Form = ({ children, onClick, btnText, className = "", isLoading }) => {
  const { handleSubmit, isSubmitting } = useFormikContext();
  return (
    <form onSubmit={handleSubmit} className={`form-model ${className}`}>
      {children}
      <ButtonMain
        type="submit"
        onClick={onClick}
        btnText={btnText}
        disabled={isSubmitting}
        isLoading={isLoading}
      />
    </form>
  );
};

export default Form;
