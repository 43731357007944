import React from "react";
import AddProfileForm from "../../../components/Form/AddProfileForm";
import "./AddProfile.scss";

const AddProfile = () => {
  return (
    <>
      <AddProfileForm />
    </>
  );
};

export default AddProfile;
