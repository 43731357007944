import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { Context } from "./context/Context";
import AddFruit from "./pages/Fruits/AddFruit/AddFruit";
import EditFruit from "./pages/Fruits/EditFruit/EditFruit";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Notfound from "./pages/NotFound/NotFound";
import AddPaper from "./pages/Papers/AddPaper/AddPaper";
import EditPaper from "./pages/Papers/EditPaper/EditPaper";
import EditProfile from "./pages/Profile/EditProfile/EditProfile";
import Profile from "./pages/Profile/Profile";
import AddVegetable from "./pages/Vegetables/AddVegetable/AddVegetable";
import EditVegetable from "./pages/Vegetables/EditVegetable/EditVegetable";
import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ShowPapers from "./pages/Papers/ShowPapers/ShowPapers";
import ShowFruits from "./pages/Fruits/ShowFruits/ShowFruits";
import ShowVegetables from "./pages/Vegetables/ShowVegetables/ShowVegetables";
import SettingsPage from "./pages/settings-page/SettingsPage";
import EditProfileInfo from "./pages/Profile/EditProfileInfo/EditProfileInfo";
import AddProfile from "./pages/Profile/AddProfile/AddProfile";
import ShowMerchants from "./pages/Profile/ShowMerchants/ShowMerchants";
import EditMerchant from "./pages/Profile/EditMerchant/EditMerchant";

const App = () => {
  const { token } = useContext(Context);
  const ProtectedRoute = (props) => {
    if (!token) {
      return <Navigate to="/login" />;
    } else {
      return props.children;
    }
  };
  return (
    <>
      <Routes>
        <Route
          path={"/"}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="/Login" element={<Login />}></Route>
        <Route
          path="/vegetables"
          element={
            <ProtectedRoute>
              <ShowVegetables />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/vegetables/add-product"
          element={
            <ProtectedRoute>
              <AddVegetable />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/vegetables/edit-product"
          element={
            <ProtectedRoute>
              <EditVegetable />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/fruits"
          element={
            <ProtectedRoute>
              <ShowFruits />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/fruits/add-product"
          element={
            <ProtectedRoute>
              <AddFruit />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/fruits/edit-product"
          element={
            <ProtectedRoute>
              <EditFruit />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/papers"
          element={
            <ProtectedRoute>
              <ShowPapers />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/papers/add-product"
          element={
            <ProtectedRoute>
              <AddPaper />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/papers/edit-product"
          element={
            <ProtectedRoute>
              <EditPaper />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/merchants"
          element={
            <ProtectedRoute>
              <ShowMerchants />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="settings/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/add-merchant"
          element={
            <ProtectedRoute>
              <AddProfile />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/edit-merchant"
          element={
            <ProtectedRoute>
              <EditMerchant />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="settings/profile/edit-profile-password"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="settings/profile/edit-profile-info"
          element={
            <ProtectedRoute>
              <EditProfileInfo />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="*" element={<Notfound />}></Route>
      </Routes>
      <ToastContainer position="top-right" />
    </>
  );
};

export default App;
