import React from "react";
import "./CategoryHead.scss";
import CategoryTitle from "../CategoryTitle/CategoryTitle";
import SearchInput from "../Input/SearchInput/SearchInput";
import ButtonAdd from "../Buttons/ButtonAdd/ButtonAdd";

const CategoryHead = ({
  arCategory,
  enCategory,
  categoryImg,
  onClick,
  onChange,
  defaultValue,
  to,
  btnText,
  addIcon,
}) => {
  return (
    <div className="category-head">
      <CategoryTitle
        arCategory={arCategory}
        enCategory={enCategory}
        categoryImg={categoryImg}
      />
      <div className="search-add-wrapper">
        <SearchInput
          onClick={onClick}
          onChange={onChange}
          defaultValue={defaultValue}
        />
        <ButtonAdd to={to} btnText={btnText} addIcon={addIcon} />
      </div>
    </div>
  );
};

export default CategoryHead;
