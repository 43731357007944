import axios from "axios";
import { toast } from "react-toastify";
const editMerchantApi = async (values, loading, toggle, editedData) => {
  loading(true);
  try {
    let response = await axios.post(`/update-user`, values);
    loading(false);

    if (response?.status === 200 && response?.data?.success) {
      editedData(response?.data?.data);
      toggle();
      toast.success("تم تعديل المنتج بنجاح");
      return;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    loading(false);
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default editMerchantApi;
