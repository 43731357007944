import React, { useContext } from "react";
import { Context } from "../../context/Context";
import ButtonDeleteMerchant from "../Buttons/ButtonDelete/ButtonDeleteMerchant";
import ButtonEdit from "../Buttons/ButtonEdit/ButtonEdit";
import CardMerchant from "../Card/CardMerchant";
import Pagination from "../Pagination/Pagination";
import "./Table.scss";

const TableMerchants = ({ tableData, paginationPages, searchText }) => {
  const { setCurrentItem } = useContext(Context);
  return (
    <>
      <div className="table-wrapper">
        {tableData?.length ? (
          tableData?.map((item, i) => (
            <>
              <CardMerchant
                key={item?.id}
                merchantName={item?.name}
                merchantPhone={item?.phone}
                merchantProduct={item?.products}
                className="card-table "
                currentItem={item?.id}
                onClick={() => setCurrentItem(item || {})}
              />
            </>
          ))
        ) : (
          <p className="card-no-product">لايوجد تجار</p>
        )}
        <table className="table">
          <tbody>
            {tableData?.length ? (
              tableData.map((item, i) => (
                <tr
                  key={i}
                  className={
                    i % 2 === 0 ? "table-row" : "table-row table-row-even"
                  }
                >
                  <td className="first-item">{i + 1} </td>
                  <td className="table-product">
                    {item?.name ? item?.name : "---"}
                  </td>
                  <td className="table-item">
                    {item?.phone ? item?.phone : "---"}
                  </td>
                  <td className="table-item">
                    {item?.products
                      ? `${item?.products}منتج `
                      : "لايوجد منتجات"}
                  </td>

                  <td className="table-btn-delete">
                    <ButtonDeleteMerchant currentItem={item?.id} />
                  </td>
                  <td className="last-item">
                    <ButtonEdit
                      to="/edit-merchant"
                      onClick={() => setCurrentItem(item || {})}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="table-row">
                <td className="table-product">لايوجد تجار</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {paginationPages?.length > 1 && (
        <Pagination paginationPages={paginationPages} searchText={searchText} />
      )}
    </>
  );
};

export default TableMerchants;
